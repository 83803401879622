import React from "react";

type Props = {
  children: any;
  colorRgb?: string;
  gradient?: string; // gradient
  href?: string;
};

export default function SocialIcon({
  children,
  colorRgb,
  gradient,
  href,
}: Props) {
  return (
    <a
      className="flex h-[60px] min-w-[60px] sm:h-[36px] group sm:min-w-[36px] cursor-pointer items-center justify-center rounded-xl text-white/90 
      transition-all duration-300 ease-in-out hover:brightness-125"
      href={href || ""}
      target="_blank"
      rel="noreferrer noopener"
      aria-label={`Go to ${href}`}
      style={{
        backgroundColor: `rgba(${colorRgb}, 1)`,
        backgroundImage: gradient,
      }}
    >
      {children}
    </a>
  );
}
