import { Dispatch, SetStateAction, useRef, useState } from "react";
import PrimaryButton from "../Forms/Button/PrimaryBtn";
import TextArea from "../Forms/TextArea";
import Modal from "../Modal";
import TextTitle from "../Typography/TextTitle";
import Image from "next/image";
import ReCAPTCHA from "react-google-recaptcha";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  setSnackbar: Dispatch<SetStateAction<boolean>>;
  setSnackbarMessage: Dispatch<SetStateAction<string>>;
  setAlertType: Dispatch<SetStateAction<string>>;
};

export default function AdvertiseOnRodeyo({
  open,
  setOpen,
  setSnackbar,
  setSnackbarMessage,
  setAlertType,
}: Props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const reRef = useRef(null);
  const sendMessage = async () => {
    setDisabled(true);
    try {
      if (message.length < 4) {
        setSnackbar(true);
        setSnackbarMessage("Please provide your email address and a message. ");
        setDisabled(false);
      } else {
        setLoading(true);
        setDisabled(true);
        // @ts-ignore
        const recaptcha = await reRef?.current?.executeAsync();
        // @ts-ignore
        reRef?.current?.reset();

        const body = JSON.stringify({
          email: email,
          name: name,
          message: message,
          subject: "Advertise Form Submission",
          token: recaptcha,
        });
        const response = await fetch("/api/sendEmail", {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        });

        const data = await response.json();

        setDisabled(false);
        if (data.success) {
          setOpen(false);
          setAlertType("success");
          setSnackbarMessage(
            "Message sent successfully! 🎉 We'll get back to you soon."
          );
          setSnackbar(true);

          setEmail("");
          setMessage("");
        }
      }
    } catch (error) {
      setDisabled(false);
      setAlertType("error");
      setSnackbarMessage("Message failed to send. Please try again.");
      setSnackbar(true);
    } finally {
      setDisabled(false);
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendMessage();
  };
  return (
    <Modal open={open} setOpen={setOpen} width="sm:w-[80%]">
      <div className="relative py-12 ">
        <div className="-z-10 rounded-full blur-[150px] bg-primary/30 w-[350px] h-[400px] absolute top-0 left-0 rotate-[20deg]" />
        <div className=" mx-auto  ">
          <div className="mx-auto max-w-2xl space-y-16 divide-y divide-gray-100 lg:mx-0 lg:max-w-none">
            <div className="w-full gap-x-8 gap-y-10 flex flex-col lg:flex-row">
              <div className="sm:!min-w-[500px]">
                <TextTitle title="Advertise on Rodeyo" />
                <div className="w-full px-2 ">
                  <form
                    onSubmit={handleSubmit}
                    className="grid gap-y-[18px] mt-[36px] w-full"
                  >
                    <input
                      id="name"
                      placeholder="your name"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      type="name"
                      name="name"
                      disabled={disabled}
                      autoFocus={true}
                      className="w-full flex-1 outline-none rounded-md border-2 border-white/20 bg-white/10 px-3.5 py-2 text-white shadow-sm  hover:border-dark-pri focus:border-dark-pri  sm:leading-6"
                    />
                    <input
                      id="email"
                      placeholder="your@email.com"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      type="email"
                      name="email"
                      disabled={disabled}
                      className="w-full flex-1 outline-none rounded-md border-2 border-white/20 bg-white/10 px-3.5 py-2 text-white shadow-sm  hover:border-dark-pri focus:border-dark-pri  sm:leading-6"
                    />

                    <TextArea
                      placeholder="Tell us a bit about what you're looking for..."
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    <ReCAPTCHA
                      size="invisible"
                      sitekey={
                        process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY
                      }
                      ref={reRef}
                      className=""
                    />
                    <PrimaryButton type="submit" loading={loading}>
                      Send
                    </PrimaryButton>
                  </form>
                </div>
              </div>
              <div className="grid w-full gap-y-6">
                <div className="grid sm:grid-cols-2 gap-x-6 gap-y-6 h-[180px]">
                  <div className="w-full rounded-2xl border border-dark-default-1 bg-dark-gray p-8 ">
                    <h3 className="text-base font-semibold leading-7 ">
                      Partnerships
                    </h3>
                    <dl className="mt-3 space-y-1 text-sm leading-6 text-light-gray">
                      <div>
                        <dt className="sr-only">Launchpad</dt>
                        <dd>
                          <a
                            className="pb-4 font-semibold text-dark-pri hover:underline "
                            href="/launchpad"
                          >
                            Go to Launchpad 🚀
                          </a>
                        </dd>
                      </div>
                      <div>
                        <dt className="sr-only">Email</dt>
                        <dd>
                          <a
                            className="font-semibold text-dark-pri hover:underline "
                            href="mailto:partners@rodeyo.com"
                          >
                            partners@rodeyo.com
                          </a>
                        </dd>
                      </div>
                      <div className="mt-1">
                        <dt className="sr-only">Phone number</dt>
                        <dd>+1 (225) 320-8005</dd>
                      </div>
                    </dl>
                  </div>

                  <div className="w-full  rounded-2xl border border-dark-default-1 bg-dark-gray p-8">
                    <h3 className="text-base font-semibold leading-7 ">
                      Say hello
                    </h3>
                    <dl className="mt-3 space-y-1 text-sm leading-6 text-light-gray">
                      <div>
                        <dt className="sr-only">Email</dt>
                        <dd>
                          <a
                            className="font-semibold text-dark-pri hover:underline "
                            href="mailto:hi@rodeyo.com"
                          >
                            hi@rodeyo.com
                          </a>
                        </dd>
                      </div>
                      <div className="mt-1">
                        <dt className="sr-only">Phone number</dt>
                        <dd>+1 (225) 320-8005</dd>
                      </div>
                    </dl>
                  </div>
                </div>

                <Image
                  src="/advertise-banner.avif"
                  alt="Advertise on Rodeyo"
                  width={400}
                  height={200}
                  className="object-cover w-full h-auto hidden lg:flex"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
