import { FaDiscord, FaLinkedinIn } from "react-icons/fa";
import {} from "react-icons/fa6";
import { FaXTwitter, FaMedium, FaPlay, FaDiscourse } from "react-icons/fa6";
import SocialIcon from "../SocialIcons/SocialIcon";
import Image from "next/image";

export default function LogoAndSocialMedia() {
  return (
    <div className="mb-5 md:mb-0 min-w-[300px]">
      <div className="flex items-center justify-center sm:justify-start font-600 text-xl">
        <Image
          src="/rodeo-logo.png"
          alt="Rodeyo Logo"
          width={42}
          height={42}
          className="w-[42px] h-[42px] mr-4 object-contain"
        />
        Rodeyo
      </div>

      <div className="mt-6 mb-8 text-center mx-auto sm:mx-0 leading-[28px] sm:justify-start  sm:text-left max-w-[75%] flex justify-center xl:max-w-none">
        Digital Ownership in Games: Own your stuff, it's that simple.
      </div>
      <div className="flex justify-evenly gap-[24px] md:justify-start">
        <SocialIcon
          href="https://dappradar.com/dapp/rodeyo"
          colorRgb="45,105,246"
        >
          <img
            src="/social-media/dappRadar.png"
            alt="dapp radar logo"
            className="my-auto h-[18px] object-cover cursor-pointer transition-all duration-300 ease-in-out hover:scale-125 !w-[24px]"
          />
        </SocialIcon>
        {/* <SocialIcon
          href="https://www.linkedin.com/company/dcentral-me/about/"
          colorRgb="0,119,181"
        >
          <FaLinkedinIn className="my-auto h-[18px] cursor-pointer transition-all duration-300 ease-in-out hover:scale-125 !w-[24px]" />
        </SocialIcon> */}
        <SocialIcon
          href="https://twitter.com/rodeyoOfficial"
          colorRgb="54, 51, 51"
        >
          <FaXTwitter className="my-auto h-[18px] cursor-pointer transition-all duration-300 ease-in-out hover:scale-125 !w-[24px]" />
        </SocialIcon>
        <SocialIcon
          href="https://www.youtube.com/@rodeyoMarketplace"
          colorRgb="255, 0, 0"
        >
          <FaPlay className="my-auto h-[18px] cursor-pointer transition-all duration-300 ease-in-out hover:scale-125 !w-[24px]" />
        </SocialIcon>
      </div>

      {/* Address */}
      <div className="text-center sm:text-left grid gap-2 mt-10 text-sm leading-6 text-gray-300">
        <div>650 Poydras Street</div>
        <div>New Orleans, Louisiana USA 🇺🇸</div>
        <div>+1 (225) 320-8005</div>
      </div>
    </div>
  );
}
